<template>
  <v-container
    id="search"
    tag="section"
  >
    <base-card dark>
      <v-img
        :src="require('@/assets/articles/peppol.png')"
        class="grey lighten-2"
        height="500"
        width="100%"
      >
        <v-row class="fill-height" align="center" justify="center">
          <v-col align="center" cols="12">
            <v-text-field
              label="Search Peppol Directory"
              append-icon="mdi-magnify"
              v-model="key"
              @keydown.enter="search"
              flat
              hide-details
              solo-inverted
              :disabled="searchInProcess"
              style="width: 550px"
            >
            </v-text-field>
            <div class="pl-2 mb-4">
              Enter the name, address, ID or any other keyword for the entity you
              are looking for.
            </div>
            <v-btn color="green" @click="search()"> Search
              <v-icon right white>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-img>

    </base-card>
    <br/>
    <base-card align="center" v-if="searchInProcess">
      <v-progress-circular
        :size="70"
        :width="10"
        indeterminate
        color="green"
      ></v-progress-circular>
    </base-card>
    <base-card
      v-if="alert && searchText!==null && totalCount===0"
    >
      <v-alert
        dense
        type="info"
      >
        No search results found for the query <strong>'{{ searchText }}'</strong>
      </v-alert>
    </base-card>
    <base-card v-if="alert && searchText!==null && totalCount!==0">
      <v-alert
        dense
        type="success"
      >
        Found {{ totalCount }} entities matching <strong>'{{ searchText }}'</strong>
      </v-alert>
    </base-card>
    <base-card v-if="alert && searchText!==null && totalCount>50">
      <v-alert
        dense
        type="warning"
      >
        Found more entities than displayed. Try to be more specific.
      </v-alert>
    </base-card>

    <base-card
      v-if="bCardList.length!==0"
    >
      <v-container>
        <v-row>

          <feed-card
            v-for="(bCard, index) in bCardList"
            :order="index"
            :size="1"
            :value="bCard"
            :key="index"
          />
        </v-row>
        <div class="text-center">
          <v-pagination
            v-if="totalCount>size"
            v-model="page"
            :length="totalPages"
            :total-visible="7"
            color="grey"
            @input="nextPage"
            circle
          ></v-pagination>
        </div>

      </v-container>
    </base-card>

    <base-card>
      <v-container>
        <v-row class="fill-height" justify="center">
          <v-col align="center">
            <h3 class="title font-weight-bold mb-2">
              Download Data
            </h3>
            <a href="https://test-directory.sandbox.peppol.eu/export/businesscards.xml">BusinessCards XML</a>
            | <a href="https://test-directory.sandbox.peppol.eu/export/businesscards-no-doc-types.xml">BusinessCards w/o
            doctypes XML</a>
            | <a href="https://test-directory.sandbox.peppol.eu/export/businesscards.csv">BusinessCards CSV</a>
            | <a href="https://test-directory.sandbox.peppol.eu/export/participants.xml">Participant IDs XML</a>
            | <a href="https://test-directory.sandbox.peppol.eu/export/participants.json">Participant IDs JSON</a>
            | <a href="https://test-directory.sandbox.peppol.eu/export/participants.csv">Participant IDs CSV</a>

          </v-col>
        </v-row>
      </v-container>
    </base-card>

  </v-container>
</template>

<script>

import {mapGetters} from 'vuex';
import businessCardService from '../../services/businesscard';


export default {
  name: 'search-body',
  components: {
    FeedCard: () => import('@/components/home/FeedCard')
  },
  data() {
    return {
      searchInProcess: false,
      searchText: '',
      key: '',
      alert: false,
      page: 1,
      size: 20,
      bCardList: [],
      totalPages: 0,
      totalCount: 0,
    };
  },
  methods: {
    search() {
      if (this.key) {
        this.searchInProcess = true;
        this.searchText = this.key;
        this.alert = false;
        this.totalCount = 0;
        this.page = 1;
        this.bCardList = [];
        businessCardService.searchBusinessCard(this.searchText, this.page - 1, this.size).then((response) => {
          this.alert = true;
          this.bCardList = response['matches'];
          this.totalCount = response['total-result-count'];
          this.totalPages = response['last-result-index'];
          this.searchInProcess = false;
        }).catch(()=>{
          this.searchInProcess = false;
        });
      }
    },
    nextPage() {
      if (this.key) {
        businessCardService.searchBusinessCard(this.searchText, this.page - 1, this.size).then((response) => {
          this.bCardList = response['matches'];
          this.totalCount = response['total-result-count'];
          this.totalPages = response['last-result-index'];
        }).catch(()=>{
          this.searchInProcess = false;
        });
      }
    },
    download() {
    }
  },
  computed: {
    ...mapGetters(['businesscards'])
  }
};

</script>
