<template>
  <v-container
    id="admin"
  >
    <base-card
      class="pa-3"
    >
      <v-container>
        <h1 class="mt-1 mb-4 pageheader">Peppol Admin</h1>
        <v-row class="fill-height pa-3" align="center" justify="center">
          <v-col align="center">
            <v-btn height="128" width="128" text @click="triggerExportSQS" class="export">
            </v-btn>
            <br/>
            <h2>Trigger Export</h2>
          </v-col>
          <v-col align="center">
            <v-btn
              height="128" width="128" text
              class="reindex"
              @click="fullReindexMinuteDialog = true"
            >
            </v-btn>
            <br/>
            <h2>Trigger Full Reindex</h2>
            <v-dialog
              v-model="fullReindexMinuteDialog"
              @keydown.esc="fullReindexMinuteDialog = false"
              @click:outside="fullReindexMinuteDialog=false"
              max-width="40%"

            >
              <v-card>
                <v-card-title class="grey lighten-2">
                  Trigger Full Reindexation
                  <v-spacer></v-spacer>
                  <v-btn icon @click="fullReindexMinuteDialog = false">
                    <v-icon color="black !important">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  Trigger full reindexation for last indexation older than the given value.
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                      >
                        <v-text-field
                          color="primary"
                          label="Value"
                          v-model="fullReindexValue"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                      >
                        <v-select
                          :items="['Minutes', 'Hours', 'Days']"
                          label="Unit of Time"
                          v-model:label="fullReindexUnit"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                          small
                          block
                          color="red darken-1"
                          @click="fullReindexMinuteDialog = false"
                        >
                          Cancel
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          small
                          block
                          text-color="white"
                          color="#4caf50"
                          @click="triggerFullReindex"
                        >
                          Trigger
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col align="center">
            <v-btn height="128" width="128" text @click="getFailedIndexationList" class="failure">
            </v-btn>
            <br/>
            <h2>List Failed Indexation Attempts</h2>
          </v-col>
        </v-row>
        <base-card>
          <v-alert
            :color="alertType"
            dense
            v-if="alertType">
            {{ alertMessage }}
          </v-alert>

          <v-alert
            dense
            type="success"
            v-if=" totalCount!==0 && failedIndexationTable"
          >
            Found {{ totalCount }} failed indexation entities
          </v-alert>
        </base-card>
        <v-row v-if="failedIndexationTable">
          <v-container>
            <v-data-table
              :headers="headers"
              :items="failedIndexationList"
              :sort-by.sync='defaultSortBy'
              :sort-desc.sync="defaultSortDesc"
              class="elevation-1"
              :items-per-page="size"
              :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
              :server-items-length="totalPages"
              :options.sync="options"
              :loading="loading"
            >
              <template #item.participantID="{ item }">{{
                  item.businesscard.participant.scheme
                }}:{{ item.businesscard.participant.value }}
              </template>
              <template #item.name="{ item }">{{
                  item.businesscard.entity[0].name[0].name
                }}
              </template>
              <template #item.countryCode="{ item }">{{
                  item.businesscard.entity[0].countrycode
                }}
              </template>
              <template #item.countryCode="{ item }">{{
                  item.businesscard.entity[0].countrycode
                }}
              </template>
              <template v-slot:item.failureCount="{ item }">
                <v-chip
                  :color="getColor(item.failureCount)"
                  dark
                >
                  {{ item.failureCount }}
                </v-chip>
              </template>
            </v-data-table>
          </v-container>
        </v-row>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>

import adminService from "@/services/adminService";
import {mapGetters} from "vuex";


export default {
  name: 'admin-body',
  data() {
    return {
      fullReindexMinuteDialog: false,
      fullReindexValue: 20170,
      fullReindexUnit: 'Minutes',
      alertMessage: '',
      alertType: null,
      elapse: null,
      size: 20,
      defaultSortBy: 'failureCount',
      defaultSortDesc: true,
      failedIndexationTable: false,
      failedIndexationList: [],
      totalPages: 0,
      totalCount: 0,
      options: {},
      loading: false,
      headers: [
        {text: 'Participant ID', align: 'center', sortable: true, value: 'participantID'},
        {text: 'Name', sortable: false, value: 'name'},
        {text: 'Country Code', sortable: false, value: 'countryCode'},
        {text: 'Last Fetch Timestamp', sortable: true, value: 'lastFetchTimestamp'},
        {text: 'Failure Count', sortable: true, value: 'failureCount'},
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.nextPage()
      },
      deep: true,
    },
  },
  methods: {
    getFailedIndexationList() {
      this.failedIndexationTable = true;
    },
    nextPage() {
      this.loading = true
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      adminService.getFailedIndexationList(page - 1, itemsPerPage, sortBy[0], sortDesc[0]).then((response) => {
        this.failedIndexationList = response['matches'];
        this.totalCount = response['totalCount'];
        this.totalPages = Math.ceil(this.totalCount / this.size);
        if (this.totalCount === 0) {
          this.alertMessage = 'No Failed Indexation Found.'
          this.showAlert('info')
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },
    triggerExportSQS() {
      this.failedIndexationTable = false;
      adminService.triggerExportSQS().then((response) => {
        this.alertMessage = 'Trigger Export Succeeded.';
        this.showAlert('success');
      });
    },
    triggerFullReindex() {
      this.failedIndexationTable = false;
      let fullReindexMinute = 0
      if (this.fullReindexUnit == 'Minutes') {
        fullReindexMinute = this.fullReindexValue;
      } else if (this.fullReindexUnit == 'Hours') {
        fullReindexMinute = this.fullReindexValue * 60;
      } else if (this.fullReindexUnit == 'Days') {
        fullReindexMinute = this.fullReindexValue * 60 * 24;
      }
      adminService.triggerFullReindex(fullReindexMinute).then((response) => {
        this.fullReindexMinuteDialog = false;
        this.alertMessage = 'Trigger Full Reindex Succeeded.';
        this.showAlert("success");
      });
    },
    getColor(failureCount) {
      if (failureCount > 10) return 'red'
      else if (failureCount > 0) return 'orange'
      else return 'green'
    },
    showAlert(type) {
      this.alertType = type

      let timer = this.showAlert.timer
      if (timer) {
        clearTimeout(timer)
      }
      this.showAlert.timer = setTimeout(() => {
        this.alertType = null
      }, 5000)

      this.elapse = 1
      let t = this.showAlert.t
      if (t) {
        clearInterval(t)
      }

      this.showAlert.t = setInterval(() => {
        if (this.elapse === 3) {
          this.elapse = 0
          clearInterval(this.showAlert.t)
        } else {
          this.elapse++
        }
      }, 1000)
    }
  },
  computed: {
    ...mapGetters(['businesscards'])
  }
}


</script>

<style scoped>
table.v-table thead th {
  font-size: 20px !important;
}

.export {
  background: url('../assets/export.png');
  background-size: cover;
}

.reindex {
  background: url('../assets/reindex.png');
  background-size: cover;
}

.failure {
  background: url('../assets/failure.png');
  background-size: cover;
}

</style>
