import adminGateway from './adminGateway';

class AdminService {
  login(username, password) {
    return adminGateway.post('/admin/login', {username: username, password: password});
  }

  triggerExportSQS() {
    return adminGateway.post('/admin/exportTrigger', {withCredentials: true});
  }

  triggerFullReindex(minutes) {
    return adminGateway.post('/admin/fullReindexTrigger', {minutes: minutes})
  }

  getFailedIndexationList(page, size, sortBy, sortDesc) {
    return adminGateway.get('/admin/osFailureList?page=' + page + '&size=' + size + '&sortBy=' + sortBy + '&sortDesc=' + sortDesc)
  }
}

export default new AdminService()
