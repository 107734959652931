<template>
  <v-container
    id="exportdata"
    tag="section"
  >
    <base-card
      class="pa-3"
    >
      <v-container>
        <h1 class="mt-1 mb-4 pageheader">
          Export data
        </h1>
        <p>
          The Directory allows you to download
          <strong>all data</strong>
          contained.
        </p>
        <h3>XML</h3>
        <ul>
          <li>
            The Directory XML data can be downloaded via the URL
            <a href="/export/businesscards.xml">/export/businesscards</a>

          </li>
          <li>
            The response MIME type is always
            <code class="pa-0">application/xml</code>.
          </li>
          <li>Responses can be cached up to 24 hours.</li>
          <li>The data is updated once a day.</li>
          <li>
            The XML Schema for the XML responses can be downloaded from

            <a href="files/directory-export-v2.xsd">/files/directory-export-v2.xsd</a>
          </li>
          <li>
            XML schema old versions:
            <a href="files/directory-export-v1.xsd">/files/directory-export-v1.xsd</a>
          </li>
        </ul>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
  export default {
    name: 'ExportDataBody',

  }
</script>

<style>
code, span.code {
  font-size: 87.5% !important;
  color: #e83e8c !important;;
  word-wrap: break-word !important;
  font-weight: 400;
  background-color: transparent !important;
}
</style>
