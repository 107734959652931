import Vue from 'vue'

const showError = (errorMessage) => {
  Vue.notify({
    group: 'notify',
    text: errorMessage,
    type: 'error',
    ignoreDuplicates: true,
    closeOnClick: true,
  })
}

const showWarning = (warningMessage) => {
  Vue.notify({
    group: 'notify',
    text: warningMessage,
    type: 'error',
    ignoreDuplicates: true,
    closeOnClick: true,
  })
}

const showNotification = (notificationMessage) => {
  Vue.notify({
    group: 'notify',
    text: notificationMessage,
    type: 'success',
    ignoreDuplicates: true,
    closeOnClick: true,
  })
}

const clear = () => {
  Vue.notify({
    group: 'notify',
    clean: true,
  })
}

export default {
  showNotification,
  showWarning,
  showError,
  clear,
}
