<template>
  <v-container
    id="howtouse"
    tag="section"
  >

    <base-card
      class="pa-3"
    >
      <v-container>
        <h2>How to use Peppol Directory</h2>
        <p>
          The Peppol Directory offers the following possibilities to search for participant information:
        </p>
        <ul>
          <li>
            <strong>Freetext search</strong>
            via a Webbrowser.
            There is both a simple search interface (available)
            as well as a more complex search interface to search for certain fields only (work in progress).

          </li>
          <li>
            <strong>Automatic search</strong>
            via a REST API (work in progress).
            Details can be found on a
            <a href="/public/menuitem-docs-rest-api">separate page</a>
          </li>
          <li>
            <strong>Download information</strong>
            and evaluate the information externally (work in progress).

          </li>
        </ul>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
  export default {
    name: 'howtouse-body',
  }
</script>
