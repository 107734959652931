<template>
  <v-container
    id="support"
    tag="section"
  >
    <base-card
      class="pa-3"
    >
      <v-container>

        <h1 class="mt-1 mb-4 pageheader">Compliant SMP Implementations</h1>
        <p>
          The Peppol Directory specification is newer than the Peppol SMP specification and
          therefore not all SMPs support Peppol Directory.
          This page contains all known SMP implementations that are known to support the Peppol Directory
          specification.
        </p>
        <p>

          Since October 3
          <sup>rd</sup>
          2019 all Peppol SMP providers MUST be able to support the
          Peppol Directory interface from their SMPs.
          Specification v1.1 is the latest one.
        </p>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
  export default {
    name: 'support-body',
  }
</script>
