import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'
import Home from '../components/home/Search'
import About from '../components/About'
import DocumentIntroduction from '../components/document/Introduction'
import DocumentHowToUse from '../components/document/HowToUse'
import DocumentRestApi from '../components/document/RestApi'
import DocumentExportData from '../components/document/ExportData'
import CompliantImplementation from '../components/support/Compliant'
import ContactUs from '../components/support/ContactUs'
import Admin from "@/components/Admin";
import Login from "@/components/Login";

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/document-introduction',
    name: 'document-introduction',
    component: DocumentIntroduction,
  },
  {
    path: '/document-how-to-use',
    name: 'document-how-to-use',
    component: DocumentHowToUse,
  },
  {
    path: '/document-rest-api',
    name: 'document-rest-api',
    component: DocumentRestApi,
  },
  {
    path: '/document-export-data',
    name: 'document-export-data',
    component: DocumentExportData,
  },
  {
    path: '/compliant-implementation',
    name: 'compliant-implementation',
    component: CompliantImplementation,
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name === 'admin' && !store.state.isLoggedin) {
    // not logged in so redirect to login page with the return url
    return next({name: 'login', query: {returnUrl: to.path}})
  }

  if (to.name === 'login' && store.state.isLoggedin) {
    // not logged in so redirect to login page with the return url
    return next({name: 'admin'})
  }
  return next();
})

export default router;
