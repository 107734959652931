import axios from 'axios'
import notify from '../utils/notify.js'
import store from '../store'
import router from "@/router";


const qs = require('qs')

const success = (res) => res.data

const error = (err) => {
  if (err.response && err.response.status && err.response.status === 400) {
    notify.showError('Bad Request');
  } else if (err.response && err.response.status && err.response.status === 401) {
    notify.showError('Unauthorized')
    store.dispatch('logout');
    router.push('/login');
  } else if (err.response && err.response.status && err.response.status === 403) {
    notify.showError('Forbidden');
    store.dispatch('logout');
    router.push('/login');
  } else if (err.response && err.response.status && err.response.status === 404) {
    notify.showError('Not Found');
  } else if (err && err.message) {
    notify.showError(err.message);
  } else {
    notify.showError('Unexpected error occurred.');
    console.log(JSON.stringify(err));
  }
  return Promise.reject(err)
}


const adminGateway = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_URL,
  timeout: 60000,
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'comma'}),
})


adminGateway.interceptors.request.use((config) => {
  if (localStorage.AuthUser) {
    try {
      const jwt = JSON.parse(localStorage.AuthUser).token
      config.headers = Object.assign({
        Authorization: 'Bearer ' + jwt
      }, config.headers)
    } catch (e) {
      console.log(e)
    }
  }

  return config
})

adminGateway.interceptors.response.use((res) => success(res), (err) => error(err))

export default adminGateway
