<template>
  <v-container
    id="about"
  >
    <base-card
      class="pa-3"
    >
      <v-container>

        <h1 class="mt-1 mb-4 pageheader">About Peppol Directory V2.0</h1>
        <h2>Introduction</h2>
        <p>
          Peppol Directory is an implementation of the Peppol Directory Specification. The most important goal of the
          updated Peppol Directory is to provide improved availability to the Directory search interfaces and API.
        </p>

        <h2>Contributors</h2>
        <ul>
          <li>Muhammet Yildiz (GITHUB: <a href="https://github.com/yerlibilgin">https://github.com/yerlibilgin</a>)</li>
          <li>Mike Aksamit (GITHUB: <a href="https://github.com/aksamit">https://github.com/aksamit</a>)</li>
          <li>Enes Yücer (GITHUB: <a href="https://github.com/enesycr">https://github.com/enesycr</a>)</li>
          <li>Philip Helger (GITHUB: <a href="https://github.com/phax">https://github.com/phax</a>)</li>
        </ul>

        <!--            </div>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'about-body',
}
</script>
