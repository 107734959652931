<template>
  <v-container
    id="about"
  >
    <base-card
      class="pa-3"
    >
      <v-container>
        <h1 class="mt-1 mb-4 pageheader">Contact US</h1>
        <v-row justify="center">
          <v-col cols="6">
            <v-text-field
              v-model="name"
              label="Name"
              :rules="nameRules"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="email"
              label="E-mail"
              :rules="emailRules"
              hide-details="auto"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-textarea
              name="input-7-1"
              filled
              label="Your Message"
              auto-grow
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="text-right">
          <v-col>
            <v-btn
              color="green"
              large
            >
              Send
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>

        </v-row>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
  export default {
    name: 'about-body',
    data: () => ({
      name: '',
      email: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }),
  }
</script>
