<template>
  <v-container
    id="login"
    tag="section"
  >
    <div>
      <v-container>
        <v-row align="center" justify="center">
          <v-col
            cols="12"
            md="5"
          >
            <v-card-text class="text-center pa-1">
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-text-field
                  v-model="username"
                  :rules="[rules.required]"
                  :validate-on-blur="false"
                  :error="error"
                  label="username"
                  name="username"
                  outlined
                  @keyup.enter="submit"
                  @change="resetErrors"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="showPassword ? 'text' : 'password'"
                  :error="error"
                  :error-messages="errorMessages"
                  label="password"
                  name="password"
                  outlined
                  @change="resetErrors"
                  @keyup.enter="submit"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>

                <v-btn
                  :loading="isLoading"
                  :disabled="isSignInDisabled"
                  block
                  text-color="white"
                  color="#4caf50"
                  @click="submit"
                >Login
                </v-btn>
              </v-form>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>


<script>
import {mapMutations} from "vuex";
import adminService from "@/services/adminService";

export default {
  name: 'login-body',
  data() {
    return {
      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      username: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required',
      },
    }
  },
  methods: {
    ...mapMutations(['setAuthUser']),
    submit() {
      if (this.$refs.form.validate()) {
        adminService.login(this.username, this.password).then(response => {
          this.setAuthUser(JSON.stringify(response));
          this.$router.push({path: '/admin'})
        }).catch(err => {
          this.errorProvider = true
        });
        this.isLoading = true

        this.isSignInDisabled = true
        this.isLoading = false
        this.isSignInDisabled = false
      }
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = 'Something is wrong!'
    },
  },
}
</script>
