import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    businessCards: require('@/data/business-cards.json'),
    docTypeIdMapping: require('@/data/doctypeid-mapping.json'),
    isLoggedin: localStorage.getItem('AuthUser') !== null ? true : false || false,
    authUser: localStorage.getItem('AuthUser') || null,
    drawer: false,
    items: [
      {
        text: 'Peppol Directory',
        href: '/',
      },
      {
        text: 'About',
        href: '/about',
      },
      {
        text: 'Document',
        href: '/document',
        subItems: [
          {
            text: 'Introduction',
            href: '/document/introduction',
          },
          {
            text: 'How to use it',
            href: '/document/how-to-use',
          },
        ],
      },
      {
        text: 'Support',
        href: '/support',
      },
      // {
      //   text: 'Login',
      //   href: '/login',
      // },
    ],
  },
  getters: {
    categories: state => {
      const categories = []

      for (const article of state.articles) {
        if (
          !article.category ||
          categories.find(category => category.text === article.category)
        ) {
          continue
        }

        const text = article.category

        categories.push({
          text,
          href: '#!',
        })
      }

      return categories.sort().slice(0, 4)
    },
    businesscards: state => {
      return state.businessCards
    },
    docTypeIdMapping: state => {
      return state.docTypeIdMapping
    },
    links: (state, getters) => {
      return state.items
    },
    getAuthUser(state) {
      return state.authUser
    },
    isLoggedin(state) {
      return state.isLoggedin;
    }
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
    setAuthUser(state, authUser) {

      if (authUser) {
        localStorage.setItem('AuthUser', authUser)
        state.isLoggedin = true
      } else {
        localStorage.removeItem('AuthUser')
        state.isLoggedin = false
      }
    },
    logout(state) {
      localStorage.removeItem('AuthUser')
      state.isLoggedin = false
    }
  },
  actions: {
    logout(context) {
      context.commit('logout')
    }
  },
})
