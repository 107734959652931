import axios from 'axios'
import notify from '../utils/notify.js'

const qs = require('qs')

const success = (res) => res.data

const error = (err) => {
  if ((err.response.data && err.response.data.errors
    && err.response.data.errors.length) || err.response.data) {
    if (err.response.data) {
      notify.showError(err.response.data)
    }
    err.response.data.errors.forEach((text) => {
      if (typeof text === 'string' && text.length) {
        notify.showError(text)
      }
    })
  } else {
    notify.showError('Unexpected error occurred.')
  }

  return Promise.reject(err)
}

const common = {}

// if (localStorage.AuthUser) {
//   common.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('AuthUser')).token
// }

const peppolGateway = axios.create({
  baseURL: process.env.VUE_APP_SEARCH_URL,
  headers: {common},
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'comma'}),
})

peppolGateway.interceptors.response.use((res) => success(res),
  (err) => error(err))

export default peppolGateway
